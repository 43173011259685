<template>
  <div class="w center_all email-configure">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>邮件推送</li>
        <li>-></li>
        <li>本刊配置</li>
        <li>-></li>
        <li>配置管理</li>
      </ul>
      <el-form ref="ruleForm" :model="ruleForm" label-width="120px" class="demo-ruleForm">
        <div class="module-setting">
          <h3>模板基本信息设置</h3>
          <div style="display:flex;margin-top:20px">
            <el-form-item label="logo：" size="zdy4" prop="bannerLogoId" label-width="60px">
              <div class="upload-img">
                <img :src="logoCoverSrc" alt />
                <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleLogoSuccess">
                  <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                </el-upload>
              </div>
              <div class="el-upload__tip">
                <el-button @click="resetLogoImg" style="margin: 0px 20px 0 0">重置</el-button>
                <p>格式支持：jpg、png、jpeg <span class="betterSize">尺寸推荐：40*40</span></p>
              </div>
            </el-form-item>
            <div>
              <el-form-item label="期刊中文名称：" prop="bannerMagNameCn" size="zdy6">
                <el-input v-model="ruleForm.bannerMagNameCn" type="text" />
              </el-form-item>
              <el-form-item label="期刊英文名称：" prop="bannerMagNameEn" size="zdy6">
                <el-input v-model="ruleForm.bannerMagNameEn" type="text" />
              </el-form-item>
              <el-form-item label="期刊官网地址：" prop="officialUrl" size="zdy6">
                <el-input v-model="ruleForm.officialUrl" type="text" />
              </el-form-item>
            </div>
            <el-form-item label="二维码：" size="zdy4" prop="bannerQrCodeId">
              <div class="upload-img">
                <img :src="codeCoverSrc" alt />
                <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleCodeSuccess">
                  <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                </el-upload>
              </div>
              <div class="el-upload__tip">
                <el-button @click="resetCodeImg" style="margin: 0px 20px 0 0">重置</el-button>
                <p>格式支持：jpg、png、jpeg <span class="betterSize">尺寸推荐：40*40</span></p>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="seuil-setting">
          <h3>推送期刊机构阈值设置</h3>
          <el-button style="margin: 10px 10px 10px 0" @click="openSettingDialog">
            阈值设置
          </el-button>
        <el-button style="margin: 10px 0" @click="openSeuilDialog">
            查看阈值
          </el-button>
          <el-dialog :visible.sync="settingDialogVisible" width="900px" :show-close="true" title="阈值设置" class="settingDialog">
            <el-checkbox-group v-model="radioList" @change="handleRadioChange">
              <el-checkbox :label="1" class="pushPercentCustom">
                <p style="display: inline-block;">
                  当前60%个期刊/机构的引用/引证发文量的总和>期刊/机构的引用/引证/发文量的总和的
                  <el-input :min="1" :max="100" type="number" style="width: 80px;pointer-events: all;" v-model="pushPercentCustomValue" />
                  %，将可能取到第60%个刊/机构。
                </p>
              </el-checkbox>
              <el-checkbox :label="2">
                <div style="display:inline-flex;margin-top: 10px;">
                  <p>设置引用引证期刊范围：</p>
                  <div>
                    <p>
                    前<el-input :disabled="!radioList.includes(2)" :min="1" :max="100" type="number" style="width: 80px" v-model="pushMagCustomValueRef" />引用期刊参与计算
                  </p>
                    <p style="margin-top: 10px;">
                    前<el-input :disabled="!radioList.includes(2)" :min="1" :max="100" type="number" style="width: 80px" v-model="pushMagCustomValueCit" />引证期刊参与计算
                  </p>
                  </div>
                </div>
              </el-checkbox>
              <el-checkbox :label="3" :disabled="!radioList.includes(2)" class="pushOrgCustom">
                <p style="display: inline-block;margin-top: 20px;">
                  基于机构发文，精选发文范围为”本刊”的作者/学者<br>
                  当机构发文的期刊，不少于
                  <el-input :disabled="!radioList.includes(3)" :min="1" :max="100" type="number"  style="width: 80px" v-model="pushOrgCustomValue" />
                  种期刊在本刊及预设的引证引用期刊范围内时，该机构的作者/学者参与推送。
                </p>
              </el-checkbox>
            </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setPushMagCustomValue">确 定</el-button>
      </span>
    </el-dialog>
        </div>
        <div class="push-setting">
          <h3>推送设置</h3>
          <p>
            发送邮件次数 >
            <el-input style="width: 100px" v-model="ruleForm.sendCount" placeholder="请输入数字" />
            ，且学者都没有反馈，则不再发送邮件给这些学者。
          </p>
          <p class="tip">说明：填写-1，表示无论几次邮件未反馈，都可进行发送。</p>
          <p>
            作者分隔符 :
            <el-input style="width: 100px" v-model="ruleForm.authorSeparator" placeholder="输入分隔符" />&nbsp;&nbsp;
            机构分隔符 :
            <el-input style="width: 100px" v-model="ruleForm.orgSeparator" placeholder="输入分隔符" />
          </p>
          <!-- <div style="margin:20px 0;">本期引文作者个性化推送：<el-switch v-model="ruleForm.openReferencePush" :active-value="1" :inactive-value="0"></el-switch></div> -->
        </div>
        <div class="personal-style">
          <h3>个性化推送设置</h3>
          <div style="margin:20px 0;">核心学者个性化推送：<el-switch @change="handleData" v-model="ruleForm.openCorePush" :active-value="1" :inactive-value="0"></el-switch></div>
          <div style="margin:20px 0;">扩展学者个性化推送：<el-switch @change="handleData" v-model="ruleForm.openExtendPush" :active-value="1" :inactive-value="0"></el-switch></div>
          <div v-if="ruleForm.openCorePush || ruleForm.openExtendPush">
            <h4>学者范围选择</h4>
            <div style="display:flex;margin:20px 0">
              <div style="display:flex;" v-if="ruleForm.openCorePush">
                <p>核心学者范围选择：</p>
                <el-select style="width:300px" v-model="openCorePushVal" multiple placeholder="请选择" @change="handleChangeOpenCorePush">
                  <el-option v-for="(item, index) in openCorePushOptions" :key="index" :label="item.typeName" :value="item.type"> </el-option>
                </el-select>
              </div>
              <div style="display:flex;margin-left:20px" v-if="ruleForm.openExtendPush">
                <p>扩展学者范围选择：</p>
                <el-select style="width:300px" v-model="openExtendPushVal" multiple placeholder="请选择" @change="handleChangeOpenExtendPush">
                  <el-option v-for="(item, index) in openExtendPushOptions" :key="index" :label="item.typeName" :value="item.type"> </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div v-if="personalTextList.length > 0">
            <h4>个性化文案设置</h4>
            <div style="margin:20px 0 20px 40px">
              <div v-for="(item, index) in personalTextList" :key="index" style="margin-bottom: 10px">
                <div style="margin-bottom: 10px">{{ index + 1 }}、{{ item.typeName }}</div>
                <div style="margin:0 0 10px 10px">邮件主题：<el-input v-model="item.topic" style="width:820px" placeholder="请输入"></el-input></div>
                <div style="margin:0 0 10px 10px;display:flex">
                  邮件正文：
                  <div style="width:820px">
                    <editor tinymce-script-src="../../../../js/tinymce/tinymce.min.js" :init="init" model-events="change keydown blur focus paste" v-model="item.content" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="open-rate">
          <h3>学者打开率设置</h3>
          <p>学者邮件打开率 >= <el-input style="width: 100px" v-model.number="ruleForm.openPercent" placeholder="请输入数字"></el-input> % 时，该学者被推送。</p>
        </div>
        <div class="custom-scholar">
          <h3>自定义学者期刊/机构设置</h3>
          <div class="custom-scholar-content">
            <div class="mag">
              <div class="part">
                <div class="text">自定义推送作者期刊：</div>
                <el-select
                  popper-class="custom-scholar-select"
                  v-model="chooseMag"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="chooseMagRemoteMethod"
                  :loading="magLoading"
                  collapse-tags
                >
                  <el-option v-for="item in chooseMagOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div class="part">
                <div class="text">排除作者期刊：</div>
                <el-select
                  popper-class="custom-scholar-select"
                  v-model="excludeMag"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="excludeMagRemoteMethod"
                  :loading="magLoading"
                  collapse-tags
                >
                  <el-option v-for="item in excludeMagOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
            </div>
            <div class="org">
              <div class="part">
                <div class="text">自定义推送学者机构：</div>
                <el-select
                  popper-class="custom-scholar-select"
                  v-model="chooseOrg"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="chooseOrgRemoteMethod"
                  :loading="orgLoading"
                  collapse-tags
                >
                  <el-option v-for="item in chooseOrgOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div class="part">
                <div class="text">排除学者机构：</div>
                <el-select
                  popper-class="custom-scholar-select"
                  v-model="excludeOrg"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="excludeOrgRemoteMethod"
                  :loading="orgLoading"
                  collapse-tags
                >
                  <el-option v-for="item in excludeOrgOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="smart-recommend">
          <h3>智能推荐设置</h3>
          <p>
            本刊支持“智能推荐”学者推送
            <el-switch v-model="ruleForm.smartPush" :active-value="1" :inactive-value="0"> </el-switch>
          </p>
          <p>
            推送
            <el-input v-model="ruleForm.smartLimitDay"></el-input>
            天后，推送数据提交算法学习。
          </p>
          <p>最低打开率：<el-input v-model="ruleForm.smartOpenPercent"></el-input> %</p>
          <p>最多推送人数：</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;整期：<el-input v-model="ruleForm.smartCount"></el-input></p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;专题：<el-input v-model="ruleForm.smartCountSubject"></el-input></p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;单篇：<el-input v-model="ruleForm.smartCountSingle"></el-input></p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;通知：<el-input v-model="ruleForm.smartCountNotice"></el-input></p>
        </div>
        <div class="btn_save">
          <el-button size="big" @click="handleSubmit">保存</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog :visible.sync="seuilDialog" width="400px" custom-class="seuilDialog">
      <p v-if="seuilSetting && seuilSetting.reference_msg">
        引用期刊阈值：第{{ seuilSetting.reference_msg.countLimitTotalCount5 }}个期刊，引用量为
        {{ seuilSetting.reference_msg.limitTotalCount5 }}
      </p>
      <p v-if="seuilSetting && seuilSetting.citation_msg">
        引证期刊阈值：第{{ seuilSetting.citation_msg.countLimitTotalCount5 }}个期刊，引证量为
        {{ seuilSetting.citation_msg.limitTotalCount5 }}
      </p>
      <p v-if="seuilSetting && seuilSetting.post_institution">
        本刊机构：第{{ seuilSetting.post_institution.countLimitTotalCount5 }}个期刊，发文量为{{ seuilSetting.post_institution.limitTotalCount5 }}
      </p>
      <p v-if="seuilSetting && seuilSetting.reference_institution">
        引用机构阈值：第{{ seuilSetting.reference_institution.countLimitTotalCount5 }}个机构，引用量为{{ seuilSetting.reference_institution.limitTotalCount5 }}
      </p>
      <p v-if="seuilSetting && seuilSetting.citation_institution">
        引证机构阈值：第{{ seuilSetting.citation_institution.countLimitTotalCount5 }}个机构，引证量为{{ seuilSetting.citation_institution.limitTotalCount5 }}
      </p>
      <p v-if="seuilSetting && seuilSetting.zxs_institution">
        本领域机构阈值：第{{ seuilSetting.zxs_institution.countLimitTotalCount5 }}个机构，发文量为{{ seuilSetting.zxs_institution.limitTotalCount5 }}
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream,uploadEditorImg,getPictureStreamPath } from '@/api/configManage'
import {
  UpdateSendCount,
  GetEmailConfig,
  GetMagChioceList,
  GetOrgChioceList,
  SetMagChioce,
  GetMagChioce,
  SetOrgChioce,
  GetOrgChioce,
  GetAccurateConfigList,
  SaveAccurateConfig,
  updatePushMagCustomValue,
  getSeuilSetting
} from '@/api/emailPush'
import { mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'Configure',
  components: { Editor },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    // logo
    logoCoverSrc() {
      return this.logoCover ? this.logoCover : this.ruleForm.bannerLogoId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.bannerLogoId}` : this.morImg
    },
    // code
    codeCoverSrc() {
      return this.codeCover ? this.codeCover : this.ruleForm.bannerQrCodeId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.bannerQrCodeId}` : this.morImg
    },
    personalTextList() {
      let arr = []
      this.allAuthorRangeList.forEach((item) => {
        this.openExtendPushVal.includes(item.type) && arr.push(item)
        this.openCorePushVal.includes(item.type) && arr.push(item)
      })
      return arr
    }
  },
  watch: {
    magId() {
      this.getConfig()
      /* this.getMagChioce(1)
      this.getMagChioce(-1)
      this.getOrgChioce(1)
      this.getOrgChioce(-1) */
    }
  },
  data() {
    return {
      uploadEditorImg,
      morImg: require('@/assets/img/img_default.png'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      ruleForm: {
        id: '',
        sendCount: '',
        openPercent: '',
        smartPush: false, //是否开启智能推送
        smartLimitDay: '', //设置任务计算模型时间
        smartOpenPercent: '', //智能推送打开率
        smartCount: '', //智能推送人数限制
        bannerQrCodeId: '', //二维码图片id
        bannerLogoId: '', //logo 图片id
        bannerMagNameZh: '', //期刊中文名
        bannerMagNameCn: '', //期刊英文名
        openPublishPush: 0, //是否开启发文精准推送  0 否 1是
        openReferencePush: 0, //是否开启引文精准推送  0 否 1是
        openCorePush: 0, //是否开启核心学者个性化推送  0 否 1是
        openExtendPush: 0 //是否开启扩展学者个性化推送 0 否 1是
      },
      init: {
        auto_focus: true,
        min_height: 400,
        menubar: false,
        language: 'zh_CN',
        skin: 'CUSTOM',
        content_css: 'CUSTOM',
        convert_urls:false,
        plugins:
          'quickbars print preview searchreplace autolink directionality visualblocks visualchars fullscreen template code codesample charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help link emoticons autosave indent2em lineheight formatpainter image',
        toolbar:
          'fontselect fontsizeselect bullist numlist outdent indent indent2em formatselect  formatpainter bold italic underline strikethrough superscript subscript removeformat forecolor backcolor alignleft aligncenter alignright alignjustify lineheight link undo redo image',
        toolbar_mode: 'sliding',
        quickbars_selection_toolbar: 'bold italic underline strikethrough subscript superscript removeformat',
        quickbars_insert_toolbar: false,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;宋体=simsun,serif,sans-serif;仿宋体=FangSong,serif,sans-serif;楷体=KaiTi,serif,sans-serif;黑体=SimHei,sans-serif;隶书=LiSu,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Times New Roman=Times New Roman,helvetica,sans-serif;',
        images_upload_handler: (blobInfo, success) => {
          this.upload(blobInfo,(e)=>{
            success(e)
          })
        }
      },
      chooseMagOptions: [],
      excludeMagOptions: [],
      chooseMag: [],
      excludeMag: [],
      magLoading: false,
      chooseOrgOptions: [],
      excludeOrgOptions: [],
      chooseOrg: [],
      excludeOrg: [],
      orgLoading: false,
      logoCover: '',
      codeCover: '',
      openCorePushVal: [],
      openExtendPushVal: [],
      openCorePushOptions: [],
      openExtendPushOptions: [],
      allAuthorRangeList: [],
      defaultAuthorRangeList: [],
      pushMagCustomValueCit: null,
      pushMagCustomValueRef: null,
      pushPercentCustomValue: 60,
      seuilDialog: false,
      seuilSetting: null,
      settingDialogVisible: false,
      checkboxValue: '',
      pushOrgCustomValue: '',
      radioList: []
    }
  },
  mounted() {
    this.getConfig()
    this.getMagChioce(1)
    this.getMagChioce(-1)
    this.getOrgChioce(1)
    this.getOrgChioce(-1)
  },
  methods: {
    getAccurateConfigList(magId) {
      let params = {
        magId: magId
      }
      GetAccurateConfigList(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          if (magId) {
            this.defaultAuthorRangeList = data
          } else {
            this.allAuthorRangeList = data
          }
          this.handleData()
        }
      })
    },
    handleData() {
      let defaultOpenCorePushOptions = []
      let defaultOpenExtendPushOptions = []
      this.openCorePushOptions = []
      this.openExtendPushOptions = []
      this.openCorePushVal = []
      this.openExtendPushVal = []
      if (this.defaultAuthorRangeList.length > 0) {
        defaultOpenCorePushOptions = this.defaultAuthorRangeList.filter((item) => item.choiceType == 0)
        defaultOpenExtendPushOptions = this.defaultAuthorRangeList.filter((item) => item.choiceType == 1)
      }
      defaultOpenCorePushOptions.forEach(({ type }) => this.openCorePushVal.push(type))
      defaultOpenExtendPushOptions.forEach(({ type }) => this.openExtendPushVal.push(type))
      if (!this.ruleForm.openCorePush) {
        this.openCorePushVal = []
      }
      if (!this.ruleForm.openExtendPush) {
        this.openExtendPushVal = []
      }
      if (this.ruleForm.openCorePush) {
        this.allAuthorRangeList.forEach((item) => {
          !this.openExtendPushVal.includes(item.type) && this.openCorePushOptions.push(item)
        })
      }
      if (this.ruleForm.openExtendPush) {
        this.allAuthorRangeList.forEach((item) => {
          !this.openCorePushVal.includes(item.type) && this.openExtendPushOptions.push(item)
        })
      }
    },
    handleChangeOpenCorePush(val) {
      this.openCorePushVal = val
      this.openExtendPushOptions = []
      this.allAuthorRangeList.forEach((item) => {
        !this.openCorePushVal.includes(item.type) && this.openExtendPushOptions.push(item)
      })
    },
    handleChangeOpenExtendPush(val) {
      this.openExtendPushVal = val
      this.openCorePushOptions = []
      this.allAuthorRangeList.forEach((item) => {
        !this.openExtendPushVal.includes(item.type) && this.openCorePushOptions.push(item)
      })
    },
    chooseMagRemoteMethod(query) {
      if (query !== '') {
        this.magLoading = true
        let params = {
          magId: this.magId,
          name: query
        }
        GetMagChioceList(params).then((resp) => {
          let { data, status } = resp.data
          this.magLoading = false
          if (status !== 0) {
            this.$message({
              type: 'error',
              message: data.message
            })
          } else {
            data.map((item) => {
              item.value = item.id
              item.label = item.name
            })
            this.chooseMagOptions = data.filter((item) => {
              return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
            })
          }
        })
      } else {
        this.chooseMagOptions = []
      }
    },
    excludeMagRemoteMethod(query) {
      if (query !== '') {
        this.magLoading = true
        let params = {
          magId: this.magId,
          name: query
        }
        GetMagChioceList(params).then((resp) => {
          let { data, status } = resp.data
          this.magLoading = false
          if (status !== 0) {
            this.$message({
              type: 'error',
              message: data.message
            })
          } else {
            data.map((item) => {
              item.value = item.id
              item.label = item.name
            })
            this.excludeMagOptions = data.filter((item) => {
              return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
            })
          }
        })
      } else {
        this.excludeMagOptions = []
      }
    },
    chooseOrgRemoteMethod(query) {
      if (query !== '') {
        this.orgLoading = true
        let params = {
          magId: this.magId,
          name: query
        }
        GetOrgChioceList(params).then((resp) => {
          let { data, status } = resp.data
          if (status !== 0) {
            this.$message({
              type: 'error',
              message: data.message
            })
          } else {
            this.orgLoading = false
            data.map((item) => {
              item.value = item.id
              item.label = item.name
            })
            this.chooseOrgOptions = data.filter((item) => {
              return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
            })
          }
        })
      } else {
        this.chooseOrgOptions = []
      }
    },
    excludeOrgRemoteMethod(query) {
      if (query !== '') {
        this.orgLoading = true
        let params = {
          magId: this.magId,
          name: query
        }
        GetOrgChioceList(params).then((resp) => {
          let { data, status } = resp.data
          if (status !== 0) {
            this.$message({
              type: 'error',
              message: data.message
            })
          } else {
            this.orgLoading = false
            data.map((item) => {
              item.value = item.id
              item.label = item.name
            })
            this.excludeOrgOptions = data.filter((item) => {
              return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
            })
          }
        })
      } else {
        this.excludeOrgOptions = []
      }
    },
    // 获取当前配置
    getConfig() {
      if (!this.magId) return
      let params = {
        magId: this.magId
      }
      GetEmailConfig(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.ruleForm = { ...data }
          // 如果值为空，则赋予初始值
          this.pushMagCustomValueCit = data.pushMagCustomValueCit
          this.pushMagCustomValueRef = data.pushMagCustomValueRef
          this.pushPercentCustomValue = data.pushPercentCustomValue
          this.pushOrgCustomValue = data.pushOrgCustomValue
          if(data.customValueType == 3) {
            this.checkboxValue = true
            this.radioList = [2,3]
          } else {
            this.radioList = [data.customValueType] || [1]
          }
          this.getAccurateConfigList()
          this.getAccurateConfigList(this.magId)
        }
      })
    },
    getMagChioce(type) {
      let params = {
        magId: this.magId,
        type: type
      }
      GetMagChioce(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          if (type === 1) {
            data.map((item) => {
              item.value = item.magId
              item.label = item.name
            })
            this.chooseMagOptions = data
            this.chooseMag = data.map((item) => {
              return item.magId
            })
          }
          if (type === -1) {
            data.map((item) => {
              item.value = item.magId
              item.label = item.name
            })
            this.excludeMagOptions = data
            this.excludeMag = data.map((item) => {
              return item.magId
            })
          }
        }
      })
    },
    getOrgChioce(type) {
      let params = {
        magId: this.magId,
        type: type
      }
      GetOrgChioce(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          if (type === 1) {
            data.map((item) => {
              item.value = item.orgId
              item.label = item.name
            })
            this.chooseOrgOptions = data
            this.chooseOrg = data.map((item) => {
              return item.orgId
            })
          }
          if (type === -1) {
            data.map((item) => {
              item.value = item.orgId
              item.label = item.name
            })
            this.excludeOrgOptions = data
            this.excludeOrg = data.map((item) => {
              return item.orgId
            })
          }
        }
      })
    },
    saveMagChioce(params) {
      SetMagChioce(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
        }
      })
    },
    saveOrgChioce(params) {
      SetOrgChioce(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
        }
      })
    },
    saveAccurateConfig() {
      if (this.personalTextList.length > 0) {
        this.personalTextList.map((item) => {
          item.magId = this.magId
        })
      }
      SaveAccurateConfig(this.personalTextList).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: resp.data.message
          })
        } else {
        }
      })
    },
    // 提交修改
    handleSubmit() {
      let chooseMagParams = {
        magId: this.magId,
        magIds: this.chooseMag ? this.chooseMag.toString() : '',
        type: 1
      }
      let excludeMagParams = {
        magId: this.magId,
        magIds: this.excludeMag ? this.excludeMag.toString() : '',
        type: -1
      }
      let chooseOrgParams = {
        magId: this.magId,
        orgIds: this.chooseOrg ? this.chooseOrg.toString() : '',
        type: 1
      }
      let excludeOrgParams = {
        magId: this.magId,
        orgIds: this.excludeOrg ? this.excludeOrg.toString() : '',
        type: -1
      }
      this.saveMagChioce(chooseMagParams)
      this.saveMagChioce(excludeMagParams)
      this.saveOrgChioce(chooseOrgParams)
      this.saveOrgChioce(excludeOrgParams)
      this.saveAccurateConfig()
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 提交
          const params = {
            ...this.ruleForm
          }
          UpdateSendCount(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message.success('保存成功')
              this.getConfig()
              this.getAccurateConfigList()
              this.getAccurateConfigList(this.magId)
            }
          })
        }
      })
    },
    // 成功上传logo
    handleLogoSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.logoCover = URL.createObjectURL(file.raw)
      this.ruleForm.bannerLogoId = ID
    },
    // 重置logo
    resetLogoImg() {
      this.logoCover = ''
      this.ruleForm.bannerLogoId = 0
    },
    // 成功上传code
    handleCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.codeCover = URL.createObjectURL(file.raw)
      this.ruleForm.bannerQrCodeId = ID
    },
    // 重置code
    resetCodeImg() {
      this.codeCover = ''
      this.ruleForm.bannerQrCodeId = 0
    },
    setPushMagCustomValue() {
      let type = this.radioList.length === 0 ? 1 : this.radioList.length === 1 ? this.radioList[0] : 3
      if (type === 3 && !this.pushOrgCustomValue) {
        this.$message.error('请填写完整')
        return
      }
      let params = {
        magId: this.magId,
        customValueType: type,
        pushMagCustomValueCit: this.pushMagCustomValueCit,
        pushMagCustomValueRef: this.pushMagCustomValueRef,
        pushMagPercentValue: this.pushPercentCustomValue,
        pushOrgCustomValue: this.pushOrgCustomValue
      }
      updatePushMagCustomValue(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.getConfig()
          this.settingDialogVisible = false
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    openSeuilDialog() {
      this.seuilDialog = true
      this.getSeuilSetting()
    },
    getSeuilSetting() {
      let params = {
        magId: this.magId
      }
      getSeuilSetting(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.seuilSetting = res.data.data
        }
      })
    },
    upload(blobInfo,fn) {
      let formData = new FormData()
      formData.append('file', blobInfo.blob())
      uploadEditorImg(formData).then(res => {
        if (res.data.status === 0) {
          getPictureStreamPath({pictureId: res.data.data.ID }).then(res1 => {
            let url = res1.data.data.url
            fn && fn(url)
          })
        } else {
          this.$message.error('图片上传失败')
          fn && fn('')
        }
      })
    },
    openSettingDialog() {
      this.settingDialogVisible = true
      this.getConfig()
    },
    handleRadioChange(val) {
      console.log(val,'val');
      if (!val.includes(2) && val.includes(3)) {
        this.radioList = []
      }
      if (val.includes(1) && val.includes(2)) {
        this.radioList = [2]
      }
    }
  }
}
</script>
<style lang="scss">
.custom-scholar-select {
  max-width: 400px;
}
.email-configure {
  .custom-scholar {
    .el-select {
      .el-input__inner {
        width: 400px;
      }
      .el-select__tags {
        .el-tag {
          word-wrap: break-word;
          word-break: break-all;
          white-space: pre-wrap !important;
          height: auto;
        }
      }
    }
  }
}
.smart-recommend {
  .el-input {
    width: 100px;
    margin: 0 10px;
  }
  .el-switch {
    margin-left: 10px;
  }
}
.personal-style {
  .el-select__tags {
    top: 3px;
    transform: inherit;
  }
}
.settingDialog {
.pushPercentCustom {
  margin-left: -24px;
  cursor: pointer;
    pointer-events: none;
  .el-checkbox__input {
    opacity: 0;
  }
}
.pushOrgCustom {
  display: inline-flex;
  align-items: center;
}
}
</style>
<style scoped lang="scss">
.email-configure {
  text-align: left;
  .center_2 {
    min-height: 500px;
    .push-setting,
    .open-rate {
      text-align: left;
      h3 {
        margin-bottom: 10px;
      }
      p {
        line-height: 40px;
      }
    }
    .open-rate {
      margin-top: 30px;
    }
    .btn_save {
      margin: 20px auto;
      text-align: center;
    }
    .custom-scholar {
      margin-top: 20px;
      .custom-scholar-content {
        display: flex;
        margin-top: 20px;
        .mag,
        .org {
          width: 50%;
          .part {
            display: flex;
            margin-bottom: 20px;
            .text {
              width: 140px;
            }
          }
        }
      }
    }
    .smart-recommend {
      p {
        display: flex;
        align-items: center;
        line-height: 30px;
        margin: 15px 0;
      }
    }
    .module-setting {
      .upload-img {
        height: 231px;
        width: 156px;
        border: 1px solid #ddd;
        background: #f4f4f4;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        img {
          height: auto;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .upload-demo {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 156px;
        height: 231px;
        .upload-btn {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      .el-upload__tip {
        color: #999;
        font-size: 14px;
        text-align: left;
        margin: 20px 0 0 0;
      }
    }
  }
}
.seuilDialog {
  p {
    margin: 10px 0;
  }
}
</style>
